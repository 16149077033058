import React, { useRef, useEffect } from 'react';
import { NavigationContainer, useLinking } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { HomeScreen, RestaurantListScreen } from '../scenes';
import { Header } from '../components';

const Stack = createStackNavigator();

function Router() {
  let navigationRef = useRef();

  let { getInitialState } = useLinking(navigationRef, {
    prefixes: [],
    config: {
      Home: { path: '' },
      RestaurantList: { path: 'place/:placeId' },
    },
  });

  let [isReady, setIsReady] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let [initialState, setInitialState] = React.useState<any>();

  useEffect(() => {
    getInitialState()
      .catch(() => {})
      .then((state) => {
        if (state !== undefined) {
          setInitialState(state);
        }

        setIsReady(true);
      });
  }, [getInitialState]);

  if (!isReady) {
    return null;
  }

  return (
    <NavigationContainer ref={navigationRef} initialState={initialState}>
      <Stack.Navigator
        screenOptions={{
          // For future feature (back button)
          // header: (props) => <Header showBack={!!props.previous} />,
          header: () => <Header />,
        }}
      >
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="RestaurantList" component={RestaurantListScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default Router;
