import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { List, Divider, Portal } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

const MOCK_AREA = [
  { id: 'area1', name: 'SDC', description: 'Summarecon Digital Center' },
  // {id: 'area2', name: 'SMS', description: 'Summarecon Mall Serpong'},
  // {id: 'area3', name: 'Aeon', description: 'Aeon Mall BSD'},
];

export default function Home() {
  let { navigate } = useNavigation();

  return (
    <Portal.Host>
      <SafeAreaView style={styles.container}>
        <List.Subheader>North Serpong</List.Subheader>
        <FlatList
          data={MOCK_AREA}
          keyExtractor={(item) => item.id}
          bounces={false}
          ItemSeparatorComponent={() => <Divider />}
          renderItem={({ item }) => (
            <List.Item
              accessibilityRole="link"
              title={item.name}
              description={item.description}
              onPress={() =>
                navigate('RestaurantList', {
                  placeId: item.id,
                })
              }
            />
          )}
        />
      </SafeAreaView>
    </Portal.Host>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#eee',
  },
});
