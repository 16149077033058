import React, { useState, useEffect } from 'react';
import { StyleSheet, FlatList, View } from 'react-native';
import {
  List,
  Divider,
  Portal,
  FAB,
  Snackbar,
  Dialog,
  Paragraph,
  Button,
} from 'react-native-paper';
import Constants from 'expo-constants';
import { useQuery, runtypes } from 'feetch';
import { Lottie } from '@crello/react-lottie';
import { SafeAreaView } from 'react-native-safe-area-context';
import Placeholder from 'react-placeholder';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function genPlaceholderData<T extends any>(
  placeholderData: T,
  amount: number,
): Array<T> {
  let data: Array<T> = [];
  for (let i = 0; i < amount; i++) {
    data.push({
      ...placeholderData,
      restaurant: { ...placeholderData.restaurant, id: i.toString() },
    });
  }
  return data;
}

type Restaurant = {
  id: string;
  name: string;
  cuisines: string;
};

type Restaurants = {
  restaurant: Restaurant;
};

const PLACEHOLDER_DATA: Restaurants = {
  restaurant: {
    id: 'Restaurant ID',
    name: 'Restaurant Name',
    cuisines: 'Restaurant Cuisines',
  },
};
const PLACEHOLDER_RESTAURANT = genPlaceholderData(PLACEHOLDER_DATA, 10);

const RestaurantScheme = runtypes.Record({
  restaurant: runtypes.Record({
    id: runtypes.String,
    name: runtypes.String,
    cuisines: runtypes.String,
  }),
});

const RestaurantListSchema = runtypes.Array(RestaurantScheme);

const RestaurantResultSchema = runtypes.Record({
  resultsFound: runtypes.Number,
  resultsStart: runtypes.Number,
  resultsShown: runtypes.Number,
  restaurants: RestaurantListSchema,
});

export default function RestaurantList() {
  let [isShuffling, setShuffling] = useState(false);
  let [restaurant, setRestaurants] = useState<Restaurants | undefined>();
  let [errorText, setErrorText] = useState('');
  let timeoutId: number | undefined = undefined;
  let { loading, payload, error, errorObject } = useQuery(
    {
      method: 'GET',
      endpoint:
        'https://developers.zomato.com/api/v2.1/search?entity_id=29884&entity_type=group',
      headers: {
        'user-key': 'ebc5b27e5873999819bcef5fb97d63bc',
      },
    },
    { schema: RestaurantResultSchema },
  );

  useEffect(() => {
    if (isShuffling) {
      // eslint-disable-next-line
      timeoutId = setTimeout(() => {
        setShuffling(false);
        if (!!!restaurant) {
          setErrorText('Shuffle Failed');
          setRestaurants(undefined);
        }
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isShuffling]);

  let onSufflePressed = (restaurants: Array<Restaurants>) => {
    setShuffling(true);
    setRestaurants(restaurants[Math.floor(Math.random() * restaurants.length)]);
  };

  let onDismissSnackbar = () => setErrorText('');

  let onDismissDialog = () => setRestaurants(undefined);

  let restaurants = PLACEHOLDER_RESTAURANT;
  if (!!payload) {
    restaurants = payload.restaurants;
  }

  if (error) {
    setErrorText(
      errorObject.message || 'Sorry, something unexpected happened.',
    );
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log('>>error', error, errorObject);
    }
  }

  if (isShuffling) {
    return (
      <View style={styles.animation}>
        <Lottie
          config={{
            animationData: require('../assets/shuffleAnimation.json'),
          }}
          speed={2}
        />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={restaurants}
        keyExtractor={({ restaurant }) => restaurant.id}
        ItemSeparatorComponent={() => <Divider />}
        ListHeaderComponent={() => (
          <List.Subheader>Summarecon Digital Center</List.Subheader>
        )}
        renderItem={({ item }) => {
          return (
            <Placeholder
              type="text"
              ready={!loading}
              rows={2}
              style={{
                padding: 15,
                width: '65%',
              }}
            >
              <List.Item
                title={item.restaurant.name}
                description={item.restaurant.cuisines}
              />
            </Placeholder>
          );
        }}
      />
      <Portal>
        <FAB
          icon="shuffle"
          disabled={loading}
          onPress={() => onSufflePressed(restaurants)}
          style={styles.fab}
        />
        <Dialog visible={!!restaurant} onDismiss={onDismissDialog}>
          <Dialog.Title>You should eat</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{restaurant?.restaurant.name}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={onDismissDialog}>Done</Button>
          </Dialog.Actions>
        </Dialog>
        <Snackbar
          visible={!!errorText}
          onDismiss={onDismissSnackbar}
          action={{
            label: 'Close',
            onPress: onDismissSnackbar,
          }}
        >
          {errorText}
        </Snackbar>
      </Portal>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#eee',
  },
  animation: {
    flex: 1,
    backgroundColor: '#0ce2ff',
    marginTop: Constants.statusBarHeight,
  },
  fab: {
    position: 'absolute',
    margin: 24,
    right: 0,
    bottom: 0,
  },
});
