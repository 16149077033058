import React from 'react';
import { Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { createClient, ClientContextProvider as FeetchProvider } from 'feetch';

import Router from './routes/router';
import { toCamelCase } from './helpers/toCamelCase';

const responseInterceptor = (_client: unknown) => async (
  _action: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
) => {
  if (response.payload) {
    let newPayload = toCamelCase(response.payload);
    return {
      ...response,
      payload: newPayload,
    };
  }

  return response;
};

const client = createClient({
  responseInterceptors: [responseInterceptor],
});

export default function App() {
  return (
    <SafeAreaProvider>
      <FeetchProvider client={client}>
        <PaperProvider>
          <Router />
        </PaperProvider>
      </FeetchProvider>
    </SafeAreaProvider>
  );
}
