import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { TextInput, Button } from 'react-native-paper';

type State = {
  name: string;
  description: string;
};

export default class NewAreaScene extends Component<{}, State> {
  state = {
    name: '',
    description: '',
  };

  render() {
    let { name, description } = this.state;
    return (
      <View style={styles.container}>
        <TextInput
          autoFocus
          mode="outlined"
          label="Area Name"
          value={name}
          onChangeText={(name) => this.setState({ name })}
          returnKeyType="next"
        />
        <TextInput
          mode="outlined"
          label="Area Description"
          value={description}
          onChangeText={(description) => this.setState({ description })}
        />
        <Button
          mode="contained"
          onPress={() => {}}
          contentStyle={styles.button}
          style={styles.buttonWrapper}
        >
          Save
        </Button>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 10,
  },
  buttonWrapper: {
    marginVertical: 5,
  },
  button: {
    paddingVertical: 10,
  },
});
