import React, { useState } from 'react';
import { Appbar, Portal, Dialog, Paragraph, Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

type Props = {
  title?: string;
  showBack?: boolean;
};

export default function Header(props: Props) {
  let { title = 'Makan Apa', showBack } = props;
  let [isInfoOpen, setInfoOpen] = useState(false);
  let { goBack } = useNavigation();

  // For future feature (back button)
  // let { params } = useRoute();
  // // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // // @ts-ignore
  // let headerTitle = params?.title || title;
  let currentYear = new Date().getFullYear();

  let toggleInfo = () => setInfoOpen(!isInfoOpen);

  return (
    <>
      <Appbar.Header>
        {showBack && <Appbar.BackAction onPress={() => goBack()} />}
        <Appbar.Content title={title} />
        {/* NOTE: For future feature */}
        {/* <Appbar.Action icon="filter-list" onPress={() => {}} /> */}
        <Appbar.Action icon="information-outline" onPress={toggleInfo} />
      </Appbar.Header>
      <Portal>
        <Dialog visible={isInfoOpen} onDismiss={toggleInfo}>
          <Dialog.Title>Info</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              Created by oshimayoan &copy; {`2019-${currentYear}.`}
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={toggleInfo}>Done</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
