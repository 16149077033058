import camelcase from 'camelcase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toCamelCase(data: Record<string, any>) {
  let keys = Object.keys(data);
  let newData = {};
  for (let i = 0; i < keys.length; i++) {
    let newKey = camelcase(keys[i]);
    let currData = data[keys[i]];
    newData = {
      ...newData,
      [newKey]:
        typeof currData === 'object'
          ? Array.isArray(currData)
            ? currData.map((data) => toCamelCase(data))
            : toCamelCase(currData)
          : currData,
    };
  }
  return newData;
}
